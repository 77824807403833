.text-copyright{
    font-family: var(--font-secondary);
}

footer{
    background-color: #E9D3FC;
    height: 80px;
    margin-top: 100px;
}


@media (min-width:1200px){
    .text-copyright{
        font-size: 18px;
    }

    .text-icons{
        font-size: 18px;
    }
    footer{
        height: 108px;
        margin-top: 140px;
    }
}
nav{
    width: 100%;
    height: 100vh;
    background-color: #D8B4F8;
    position: fixed;
    top: 0;
    animation: show 1s 1;
}
ul{
    list-style-type: none;
}
li{
    font-family: var(--font-secondary);
    font-size: 40px;
    cursor: pointer;
}


@keyframes show {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}
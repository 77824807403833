.text-skill{
    font-weight: 500;
}

.img-skill{
    width: 40px;
    
}
.container-skills{
    display: grid;
    grid-template-columns: repeat(3,1fr);

}
@media (min-width:744px){
    .container-skills {display: flex;}
}
@media (min-width:1200px){

     .gap-8{
         gap: 8px;
     }
     .img-skill{
         width: 80px;
     }
     .gap-9{
         gap: 150px;
     }
}
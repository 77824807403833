.w-40{
    width: 80%!important;
}
.mySwiper{
    height: 400px;
}

@media(min-width:744px){
    .w-40{
        width: 40%!important;
    }
    .mySwiper{
        height: 75%;
    }
 
}

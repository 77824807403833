

.title{
    font-size: 1rem;
}
.text-edAndWork{
    font-size: 12px;
}
@media (min-width:744px){
    .text-edAndWork{
        font-size: 14px;
    }
}

@media (min-width:1200px){
    .text-edAndWork{
        font-size: 18px;
    }
    .title{
        font-size: 1.5rem!important;
    }
}
.fw-500{
    font-weight: 500;
}

.form-control{
    background-color: #E9D3FC;
    border: 1px solid #9B51E0;
}
label{
    font-weight: 600;
}
.btn-form{
    border: 1px solid #9B51E0;
    background: #D8B4F8;
}
.form-floating>label {
    z-index: 1;
}
.gap-media{
    gap: 10%;
}
.border-contact{
    border-right:none!important ;
}

 .border-bottom{
    padding-bottom: 80px;
 }
 .contianer-contact{
    flex-direction: column;
    align-items: center;
    gap: 15vh;
}
.media-icon{
    width: 40px;
}
@media(min-width:744px){
    .form-size{
        width: 70%!important;
    }
}
@media(min-width:1200px){
    .fw-500{
        font-weight: 500;
    }
    .pe-123{
        padding-right: 123px;
    }
    .ps-123{
        padding-left: 123px;
    }
    
    label{
        font-weight: 600;
    }
    .media-icon{
        width: 60px;
    }
    .contianer-contact{
        flex-direction: row;
    }
    .border-end{
        border-right:var(--bs-border-width) var(--bs-border-style) var(--bs-border-color)!important;
    }
    .border-bottom{
        border-bottom: 0!important;
    }
}
.card-img{
    border-radius: 8px;
}
.card-container{
    border-radius: 10px;
    border: 2px solid #333;
    padding: 0;

}
.project-name{
    font-family: var(--font-secondary);
    font-size:20px ;
}

.info-section{
    backdrop-filter: blur(30px);
    background: rgba(255, 255, 255, 0.4);
    height: 20%;
    overflow: hidden;
}

.allInfo{
    animation: showDesc 1s 1;
    animation-fill-mode: forwards;
}
.allInfo>.project-desc,.allInfo>.link-go{
    display: block!important;
}
.project-desc,.link-go{
    display: none;
}
.link-go{
    font-family: var(--font-secondary);
}
.btn-primary{
    background: radial-gradient(94.06% 63.88% at 26.85% 17.98%, rgba(249, 248, 113, 0.30) 6.77%, rgba(255, 218, 119, 0.30) 23.96%, rgba(255, 191, 153, 0.30) 35.94%, rgba(255, 176, 195, 0.30) 57.29%, rgba(255, 175, 230, 0.30) 75%, rgba(222, 179, 245, 0.30) 100%);
    box-shadow: 1px 1px 10px #6d6d6d;
}
.btn-primary:hover{
    background: radial-gradient(94.06% 63.88% at 26.85% 17.98%, rgba(249, 248, 113, 0.30) 6.77%, rgba(255, 218, 119, 0.30) 23.96%, rgba(255, 191, 153, 0.30) 35.94%, rgba(255, 176, 195, 0.30) 57.29%, rgba(255, 175, 230, 0.30) 75%, rgba(222, 179, 245, 0.30) 100%);

}

@media (min-width:1200px){

    .card-container{
        border-radius: 10px;
        border: 2px solid #333;
        width: 35vw;
        height: 30vw;
    }
    .project-name{
        padding-bottom: 2px;
        font-size: 20px;
    }
    .project-desc{
        font-size: 14px;
    }
    .link-go{
        font-size: 14px;
    }
    .card-img{
        height: 100%;
       
    }
}
@media (min-width:1600px){
    .project-name{
        padding-bottom: 27px;
        font-size: 30px;
    }
    .project-desc{
        font-size: 20px;
    }
    .link-go{
        font-size: 20px;
    }
}
@keyframes showDesc {
    from{
        height: 20%;
    }
    to{
        height: 100%;
    }
}
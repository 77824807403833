.title-edAndwork{
    font-family: var(--font-secondary);
    margin: 0;
    font-size: 40px;
    padding-bottom: 30px;
}

.border-secondary{
    border: none!important;
}

.p-info-w-e{
    padding-bottom: 40px;
}
@media (min-width:1200px){
    .border-secondary{
        border: none;
    }
    .ps-experience{
        padding-left: 160px;
    }
    
}
.figure1{
  left: -80px;
}
.size-figures{
  width: 90%;
}

.line-hamb{
  background-color: #333;
  transition: transform 1s;
}

.turn-left{
  transform: rotate(-33deg) translate(3px);
  
}
.turn-right{
  transform: rotate(33deg) translate(5px, 4px);
}

.figure1{
  left: -80px;
}
.figure2{
  top: 900px;
}
.figure3{
  top: 2000px;
}
.figure4{
  top: 2900px;
}
.figureBis{
  top:3800px
}
  .hamburger {
    width: 30px;
    height: 20px;
    top: 20px;
    right: 15px;
  }
  .line-hamb{
    height: 2px;
  }

  .contianer-distance{
    padding-top: 100px;
  }

  @media(min-width:744px){
    .size-figures{
      width: 40%;
    }
    .figure2{
      top: 50vh;
    }
    .figure4{
      top: 50%;
    }
    .figure3{
      bottom: 50%;
    }
    .figureBis{
      display: none;
    }
  }
@media (min-width:1200px){

  .hamburger{
    width: 50px;
    height: 40px;
    top: 50px;
    right: 50px;
  }
  
  .line-hamb{
    height: 4px;
  }
  .contianer-distance{
    padding-top: 115px;
  }
  .turn-right{
    transform: rotate(40deg) translate(13px, 12px);
  }
  .turn-left{
    transform: rotate(-45deg);
  }
}
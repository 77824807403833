@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;700&family=Source+Sans+3:wght@300;400;500&display=swap');

:root{
  --font-secondary:'Playfair Display', "serif";
  --font-primary:'Source Sans 3', "sans-serif";
}
body {
  margin: 0;
  font-family: var(--font-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p{
  font-size: 14px;
}


.subtitle{
  font-family: var(--font-secondary);
  font-weight: 400;
  margin-bottom: 0;
  font-size: 60px
}


.distance-section-top{
  padding-top: 95px;
  padding-left: 50px;
  padding-right: 50px;
}
  .padding-section{
    padding-left: 50px;
    padding-right: 50px;
  }
  .section{
    padding-bottom: 20vh;
  }

@media (min-width:744px){
  p{
    font-size: 16px;
  }
  .distance-section-top{
    padding-top: 95px;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width:1200px){

  .padding-section{
    padding-left: 200px;
    padding-right: 200px;
  }
  p{
    font-size: 25px;
  }
  
  .subtitle{
    font-size: 100px;
    padding-top: 20vh;
  }
  .section{
    padding-bottom: 95px;
  }
  .w-30{
    width: 30%;
  }

}

h1{
    font-family: 'Playfair Display', serif;
    font-size: 40px;
    font-weight: bold;
}
header{
    padding-top:20vh;
    padding-bottom: 20vh;
}
.b-black{
    border: 2px solid #333;
    top: 60px;
    right: 20px
}
.b-bourbon{
    border: 2px solid #A6737F;
    top: 70px;
    right: 30px
}
.container-img{
    padding: 80px 40px 0px 40px;
}
.profile-size{
    border-radius: 8px;
}

.profile-size{
    width: 200px;
    height: 244px;
}
@media (min-width:744px){
    .header{
        flex-direction: row!important;
    }
    .text-header{
        font-size: 14px;
        width: 354px;
    }
    .container-img{
        padding: 20px;
    }
    .b-black{
        top: 0;
        right: 0px
    }
    .b-bourbon{
        top: 10px;
        right: 10px
    }
}
@media  (min-width:1200px){
    h1{
        font-size: 80px;
        font-weight: 700;
        padding-bottom: 60px;
    }
    .text-header{
       width: 354px;
    }

    .container-img{
        padding: 40px;
    }
    .profile-size{
        width: 350px;
        height: 427px;
    }
    .b-bourbon{
        top:20px;
        right: 20px;
    
    }
    .b-black{
        top:0px;
        right: 0px;
    }
}